.registro-sec {
  p {
    margin-bottom: 1rem;
  }

  form.registro {

    input, textarea, select {
      margin-top: 1rem;
      margin-bottom: 1rem;
      box-shadow: none;
      border-radius: 0 !important;
      border: none;
      border-bottom: 1px solid $color_gray;
      font-size: 1.6rem;
      padding-left: 0;

      &:active, &:focus {
        border-bottom-color: $color_blue;
      }
    }

    #mensaje{
      color:$color_blue;
      text-transform: uppercase;
      padding-top:2rem;
      text-align: center;
      font-size: 2.7rem;
      font-weight: 600;
      width: 100%;
      display: block;
    }

    textarea {
      width: 100%;
      height: 90px;
    }

    fieldset {
      display: inline-block;
      width: 48%;
      margin-right: 2%;

      @media (max-width: 600px){
        width: 90%;
        display: block;
        //padding: 2rem;
        margin: 0 auto !important;
      }

      &:nth-child(odd) {
        margin-right: 0;
      }

    }

    label {
      display: none;
    }

    .planes {
      display: block;

      @media (min-width: 900px) {
        display: flex;
      }

      .item-plan {
        transition: all .3s;
        text-align: center;
        padding: 1rem;
        margin: 2rem auto;
        border-radius: .4rem;
        box-shadow: 0 0 1px $color_gray;
        padding-bottom: 6rem;
        padding-top: 6rem;
        border: 2px solid white;
        width: 90%;
        display: block;

        @media (min-width: 900px) {
          flex-grow: 1;
          flex-basis: 0;
          margin: 1rem;

        }



        &:hover {
          @extend .item-active;
        }

        h3 {
          font-size: 5rem;
          margin-bottom: 1rem;
        }

        span {
          display: block;
          &.btn {
            margin: 0 auto;
            width: 80%;
            color: $color_blue;
            border-color: $color_blue;
            background: transparent;
          }
          &.precio {
            font-weight: 600;
            font-size: 5rem;
          }
        }

      }
    }

    #registrarse{
      margin-top: 2rem;
    }
  }

}

.item-active {
  box-shadow: 0 0 12px 1px $color_gray !important;
  transform: scale(1.04) !important;
  //border:2px solid $color_blue;

  span.precio {

    color: $color_green;
  }

  span.btn {

    color: white !important;
    background-color: $color_blue !important;
  }

}