footer.no-home {

  form {
    position: relative;

    #newsletter-success {
      color: white;
      padding: 1rem;
      position: absolute;
      left: 50%;
      display: block !important;
      transform: translateX(-50%);
    }
  }
}