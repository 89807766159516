.pagar-section {

  h2.nombre {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  h4.email {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  div.mercadopago {
    h3 {
      text-align: center;
    }
    img {
      max-height: 100px;
      margin: 3rem auto 0 auto;
      display: block;
    }
  }

  .item-pago {
    padding: 3rem;
    box-shadow: 0 0 2px 1px #e0e0e0;
    text-align: center;
    margin-bottom: 2rem;

    p {
      margin: 0
    }
    span {
      display: block;

      &.valor {
        font-size: 4rem;
      }
    }

    a.btn {
      color: white;
    }
  }
}

.pagar {
  p {
    margin-bottom: 1rem;
  }
}
.banco{
  p{
    margin-bottom: 0;
  }
}