.footer-upper-content .subscription input {
  border: 1px solid #ffffff;
  background-color: transparent;
  height: 50px;
  /*border-radius: 25px;*/
  line-height: 48px;
  text-align: center;
  width: 100%;
  margin: 3px;
  color: #ffffff;
}

.download-platform {
  a {
    color: white !important;
  }
}

form.subscription {

  textarea {
    border: 1px solid #ffffff !important;
    background-color: transparent !important;
    height: 180px;
    //border-radius: 5px;
    line-height: 48px;
    text-align: center;
    width: 100%;
    margin: 3px;
    font-size: 16px !important;
    color: #ffffff !important;
  }
}

#pricing-section {
  padding-top: 130px;
  display: block;

  h1, h2 {
    font-size: 2.3em;
    text-align: center;
    text-transform: uppercase;
    padding: 0 0 1em 0;
  }
}

.navbar-default.affix {
  padding: .7em 0;

  ul {
    background: transparent !important;
  }
}

.footer-upper-content .subscription {
  margin-top: 15px;
}

#send-consulta {
  margin-top: 10px;
}
.prueba{
  text-align: center;
  padding-bottom: 3em;
  p{
    margin: 10px 0;
  }
}